
import {
  computed, defineComponent, PropType, ref, Ref, watch,
} from 'vue';

import { useRoute, useRouter } from 'vue-router/composables';
import { useI18n } from '@/plugins/vue-i18n';

import { IBreadcrumb, PlayerSessionStatus, UUID } from '@/domains/common';
import { IQuiz, toQuizQuestion, toQuizResult } from '@/domains/quiz';
import { IAtom, AtomType } from '@/domains/atom';
import { IButtonsState } from '@/domains/assignment';

import { getRunTestAgainButtonTimePart, getTimeBetweenAttempts } from '@/helpers/time/blockingRunQuizButton';

import * as LxpQuizTestService from '@/services/api/lxp-quiz-test';

import EmptyBlock from '@/components/ui/EmptyBlock.vue';
import TTBackButton from '@/components/ui/TTBackButton.vue';
import QuizIntroDescriptionPanel from '@/components/quiz/QuizIntroDescriptionPanel.vue';
import TestRules from '@/components/ui/TestRules/TestRules.vue';
import StepsButtons from '@/domains/assignment/components/StepsButtons/StepsButtons.vue';

import Names from '@/plugins/vue-router/routeNames';
import { useDi } from '@/plugins';

export default defineComponent({
  name: 'QuizIntro',

  components: {
    EmptyBlock,
    TTBackButton,
    QuizIntroDescriptionPanel,
    TestRules,
    StepsButtons,
  },

  inject: ['Names'],

  props: {
    track: {
      type: Object as PropType<IAtom>,
      required: true,
    },

    buttonsState: {
      type: Object as PropType<IButtonsState>,
      default: () => ({
        prev: { enabled: false },
        isShowNextStepLinearWarning: false,
      }),
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const { notify } = useDi();

    const isLoading = ref<boolean>(true);
    const isError = ref<boolean>(false);
    const isSessionStarting = ref<boolean>(false);
    const quiz = ref<IQuiz>({
      playerSessionStatus: PlayerSessionStatus.NEW,
      name: '',
      description: '',
      questionsCount: 0,
      testTimeout: 0,
      freeFlow: false,
      results: null,
      maxAttempts: 0,
      attemptsCount: 0,
      nextAttemptAt: '',
    });
    const atomType = ref<AtomType>(AtomType.QUIZ);

    const breadcrumbs: Ref<IBreadcrumb[]> = computed(() => {
      return [
        {
          text: t('LearningTracks.title'),
          to: {
            name: Names.R_APP_LEARNING_TRACKS,
          },
          'data-test-label': 'my-learning',
        },
        {
          text: props.track.name ?? '',
          to: {
            name: Names.R_APP_LEARNING_TRACK,
          },
          'data-test-label': 'track-name',
        },
        {
          text: t('LearningTracks.materials'),
          disabled: true,
          'data-test-label': 'track-materials',
        },
      ];
    });
    const playerSessionId: Ref<number> = computed(() => Number(route.params.playerSessionId));
    const stepId: Ref<UUID> = computed(() => route.params.stepId);
    const timeBetweenAttempts: Ref<{days: number, hours: number, minutes: number }> = computed(() =>
      getTimeBetweenAttempts(quiz.value?.nextAttemptAt ?? ''));
    const buttonText: Ref<string> = computed(() => {
      const str = getRunTestAgainButtonTimePart(timeBetweenAttempts.value);

      switch (quiz.value!.playerSessionStatus) {
        case PlayerSessionStatus.ACTIVE:
          return t('QuizIntro.buttonText.continue');
        case PlayerSessionStatus.NEW:
        default:
          return str.length
            ? t('QuizIntro.buttonText.start.time', { time: str })
            : t('QuizIntro.buttonText.start.withoutTime');
      }
    });
    // Note: переводим минуты в секунды
    const quizTimeoutSeconds: Ref<number> = computed(() => (quiz.value?.testTimeout ?? 0) * 60);
    const hasNextAttemptsTimeFlag: Ref<boolean> = computed(() =>
      timeBetweenAttempts.value.days > 0
      || timeBetweenAttempts.value.hours > 0
      || timeBetweenAttempts.value.minutes > 0);
    const isQuizSucceedAbsolutely: Ref<boolean> = computed(() =>
      quiz.value?.playerSessionStatus === PlayerSessionStatus.COMPLETED && quiz.value?.results?.percent === 1);
    const isQuizFailedAbsolutely: Ref<boolean> = computed(() =>
      quiz.value?.playerSessionStatus === PlayerSessionStatus.COMPLETED && !quiz.value?.results?.passed
      && quiz.value?.maxAttempts > 0 && quiz.value?.attemptsCount === quiz.value?.maxAttempts);

    const redirectToResult = () => {
      if ((isQuizSucceedAbsolutely.value || isQuizFailedAbsolutely.value)) {
        router.push(toQuizResult({
          playerSessionId: playerSessionId.value,
          stepId: stepId.value,
        }));
      }
    };
    const fetchQuestions = async () => {
      try {
        await LxpQuizTestService.testSessionQuestionsGet({
          params: {
            playerSessionId: playerSessionId.value,
          },
        });
      } catch (e: any) {
        if (e.response.status === 422) {
          await router.push(toQuizResult({
            playerSessionId: playerSessionId.value,
          }));
        }
      }
    };
    const fetchQuiz = async () => {
      isLoading.value = true;

      const params = {
        playerSessionId: playerSessionId.value,
      };

      try {
        const quizResponse = await LxpQuizTestService.testInfoGet(params);

        if (quiz.value?.playerSessionStatus === PlayerSessionStatus.ACTIVE) {
          await fetchQuestions();
        }

        quiz.value = quizResponse;
      } catch (e: any) {
        notify.error({ content: e.message });
        isError.value = true;
      } finally {
        isLoading.value = false;
      }
    };
    const nextStep = () => {
      emit('step:next');
      emit('next-step');
    };
    const previousStep = () => {
      emit('step:prev');
      emit('prev-step');
    };
    const runTest = async () => {
      isSessionStarting.value = true;
      const params = {
        playerSessionId: playerSessionId.value,
      };

      try {
        const { playerSessionStatus } = await LxpQuizTestService.testSessionRun({ params });

        if ([PlayerSessionStatus.NEW, PlayerSessionStatus.ACTIVE].includes(playerSessionStatus)) {
          await router.push(toQuizQuestion());
        }
      } catch (e: any) {
        notify.error({ content: e.message });
      } finally {
        isSessionStarting.value = false;
      }
    };
    const runTestAndGetQuestions = async () => {
      await fetchQuestions();
      await runTest();
    };

    watch(
      () => route.params.playerSessionId,
      async () => {
        await fetchQuiz();
        redirectToResult();
      }, { immediate: true },
    );

    return {
      isLoading,
      isError,
      breadcrumbs,
      quiz,
      atomType,
      quizTimeoutSeconds,
      buttonText,
      hasNextAttemptsTimeFlag,
      isSessionStarting,
      timeBetweenAttempts,
      nextStep,
      previousStep,
      runTestAndGetQuestions,
    };
  },
});
